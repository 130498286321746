import React, {
  useState,
  useCallback,
  useEffect,
  BaseHTMLAttributes
} from 'react'
import useEmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType
} from 'embla-carousel-react'
import { ReactComponent as SliderArrow } from '@svgs/slider-arrow.svg'
import carouselStyles from './styles.module.scss'

export type { EmblaCarouselType as CarouselType }

const preventEdgeScrolling = (embla: EmblaCarouselType) => {
  const { limit, target, location, scrollTo } = embla.internalEngine()

  return () => {
    if (limit.reachedMax(target.get())) {
      if (limit.reachedMax(location.get())) location.set(limit.max)
      target.set(limit.max)
      scrollTo.distance(0, false)
    }
    if (limit.reachedMin(target.get())) {
      if (limit.reachedMin(location.get())) location.set(limit.min)
      target.set(limit.min)
      scrollTo.distance(0, false)
    }
  }
}

type ButtonProps = {
  enabled: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  variant?: ButtonsVariant
}
const PrevButton = ({
  enabled,
  onClick,
  variant = 'secondary'
}: ButtonProps) => (
  <button
    type="button"
    className={`${carouselStyles.carouselButton} ${
      carouselStyles.carouselButton_prev
    } ${carouselStyles[`carouselButton__${variant}`]}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <SliderArrow width="40" height="40" />
  </button>
)

const NextButton = ({
  enabled,
  onClick,
  variant = 'secondary'
}: ButtonProps) => (
  <button
    type="button"
    className={`${carouselStyles.carouselButton} ${
      carouselStyles.carouselButton_next
    } ${carouselStyles[`carouselButton__${variant}`]}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <SliderArrow width="40" height="40" />
  </button>
)

type CarouselSlideProps = {
  isActive?: boolean
  classes?: string
} & BaseHTMLAttributes<HTMLElement>
export function CarouselSlide({
  isActive = false,
  classes = '',
  children,
  ...props
}: React.PropsWithChildren<CarouselSlideProps>) {
  return (
    <div
      aria-hidden={!isActive}
      className={`${carouselStyles.carousel__slide} ${
        isActive ? carouselStyles.carousel__slide_isActive : ''
      } ${classes}`}
      {...props}
    >
      {children}
    </div>
  )
}

export type ButtonsVariant = 'primary' | 'secondary'

type CarouselProps = {
  children: React.ReactNode
  settings?: EmblaOptionsType & {
    afterChange?: (slide: number) => void
    buttons?: boolean
    fade?: boolean
    dots?: boolean
    buttonsVariant?: ButtonsVariant
  }
  instance?: (embla: EmblaCarouselType) => void
  carouselClasses?: string
  carouselDotsClasses?: string
}

function Carousel({
  children,
  instance = () => undefined,
  settings = {},
  carouselClasses = '',
  carouselDotsClasses = ''
}: CarouselProps) {
  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    skipSnaps: false,
    speed: 25,
    ...settings
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [dots, setDots] = useState<number[]>([])
  const [isReady, setIsReady] = useState(false)

  const scrollPrev = useCallback(() => {
    embla?.scrollPrev()
  }, [embla])
  const scrollNext = useCallback(() => {
    embla?.scrollNext()
  }, [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
    if (settings.afterChange) {
      settings.afterChange(embla.selectedScrollSnap())
    }
  }, [embla, setSelectedIndex])

  const handleDotSelect = (index: number) => {
    setSelectedIndex(index)
    embla?.scrollTo(index)
  }

  useEffect(() => {
    if (!embla) return

    embla.on('init', () => {
      setIsReady(true)
    })

    embla.on('select', onSelect)

    if (!settings.loop) {
      embla.on('scroll', preventEdgeScrolling(embla))
    }
    instance(embla)
  }, [embla])

  useEffect(() => {
    if (!embla) return

    onSelect()
    setDots(embla?.scrollSnapList())
  }, [embla, onSelect, setDots, settings])

  useEffect(() => {
    if (!embla) return
    embla.reInit()
    embla.scrollTo(settings.startIndex || 0)
    setDots(embla?.scrollSnapList())
  }, [React.Children.count(children)])

  return (
    <>
      <div
        className={`${carouselStyles.carousel} ${carouselClasses} ${
          settings.buttonsVariant ? carouselStyles[settings.buttonsVariant] : ''
        }`}
        ref={emblaRef}
      >
        <div
          className={`${carouselStyles.carousel__itemsContainer} ${
            settings.fade ? carouselStyles.fade : ''
          } ${isReady ? carouselStyles.isReady : ''}`}
        >
          {React.Children.map(children, (child, index) => {
            return React.isValidElement(child)
              ? React.cloneElement(
                  child as React.ReactElement<{ isActive: boolean }>,
                  {
                    isActive: index === selectedIndex
                  }
                )
              : null
          })}
        </div>
        {settings.buttons && (
          <div
            className={`grid__container ${carouselStyles.buttons__container}`}
          >
            <PrevButton
              onClick={scrollPrev}
              enabled={prevBtnEnabled}
              variant={settings.buttonsVariant}
            />
            <NextButton
              onClick={scrollNext}
              enabled={nextBtnEnabled}
              variant={settings.buttonsVariant}
            />
          </div>
        )}
      </div>

      {settings.dots && (
        <div
          className={`${carouselStyles.carousel__dots} ${carouselDotsClasses}`}
        >
          {dots.map((_, index) => (
            <button
              aria-label={`slide ${index}`}
              type="button"
              key={index}
              onClick={() => {
                handleDotSelect(index)
              }}
              className={`${carouselStyles.carousel__dot} ${
                index === selectedIndex
                  ? `${carouselStyles.selected} selected`
                  : ''
              }`}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default Carousel
