import React from 'react'
import styles from './styles.module.scss'

type SectionHeaderProps = {
  byline?: string
  title: string
  className?: string
  inline?: boolean
  variant?: 'default' | 'left_aligned'
}

const SectionHeader = ({
  byline,
  title,
  className = '',
  inline,
  variant = 'default'
}: SectionHeaderProps) => (
  <div
    className={`${styles.sectionHeader} ${
      inline ? styles.sectionHeader_inline : ''
    } ${styles[variant]} ${className}`}
  >
    {byline && <p className={styles.sectionHeader__byline}>{byline}</p>}
    <h2>{title}</h2>
  </div>
)

export default SectionHeader
