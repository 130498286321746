import React from 'react'
import Translations from '@helpers/translations'
import { ReactComponent as TickCircleIcon } from '@svgs/tick-circle.svg'
import Stars from '@components/ui/Stars'
import styles from './reviewItemStyles.module.scss'

export type ReviewItemProps = {
  title: string
  description: string
  author: string
  stars: number
  location?: string
}

function ReviewItem({
  title,
  description,
  stars,
  author,
  location
}: ReviewItemProps) {
  return (
    <article className={styles.reviewItem}>
      <Stars value={stars} color="color2" />
      <h3 className={styles.reviewItem__title}>{title}</h3>
      <p className={styles.reviewItem__description}>{description}</p>
      <footer className={styles.reviewItem__author}>
        <TickCircleIcon width="25" height="25" />
        <p>
          <span>{author}</span>
          {location
            ? ` ${Translations.localise('common.reviews.from')} ${location}`
            : ''}
        </p>
      </footer>
    </article>
  )
}

export default ReviewItem
