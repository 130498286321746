import React from 'react'
import SectionHeader from '@components/ui/SectionHeader'
import Carousel from '@components/ui/Carousel'
import ReviewItem, { ReviewItemProps } from './ReviewItem'
import styles from './styles.module.scss'

type ReviewsProps = {
  title: string | null
  byline: string | null
  reviews: {
    [key: number]: ReviewItemProps
  }
}

function Reviews({ title, byline, reviews }: ReviewsProps) {
  const reviewsList = Object.values(reviews)
  return (
    <>
      {!!reviewsList?.length && (
        <section className={styles.reviews}>
          {!!title && (
            <SectionHeader
              title={title}
              byline={byline || undefined}
              className={styles.sectionHeader}
            />
          )}
          <Carousel
            carouselClasses={styles.carousel}
            settings={{
              dots: true,
              buttons: true,
              buttonsVariant: 'secondary',
              loop: true
            }}
          >
            {reviewsList.map(review => (
              <ReviewItem
                key={`review-${review.author}-${review.stars}`}
                {...review}
              />
            ))}
          </Carousel>
        </section>
      )}
    </>
  )
}

export default Reviews
